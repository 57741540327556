import { Loader } from "@progress/kendo-react-indicators";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import accountService from "../services/account.service";
import { AxiosError } from "axios";
import { Error } from "@progress/kendo-react-labels";
import { Dictionary } from "../types/Dictionary";
import useLocale from "../hooks/useLocale";
import Swal from "sweetalert2";
import useTranslation from "../hooks/useTranslation";

const Logout: React.FC = () => {
  const trans = useTranslation("logout");
  const localeCtx = useLocale();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [error, setError] = useState<string>();
  const clientId = searchParams.get("clientId");
  const redirectUri = searchParams.get("redirectUri");

  const logoutUser = async () => {
    try {
      if (clientId) {
        const success = await accountService.logout(clientId);
        if(success){
          navigate(
            `/login?clientId=${clientId}${
              redirectUri !== null ? `&redirectUri=` + redirectUri : ""
            }`,
            {
              replace: true,
            }
          );
        }
        else{
          navigate(`/unable-to-logout`);
        }
      }
    } catch (err) {
      console.error(err);
      if (err instanceof AxiosError) {
        const errCode = err.response?.data?.code;
        if (errCode === "TOKEN_INVALID_OR_EXPIRED") {
          navigate(`/unauthorized`, { replace: true });
        } else {
          const errMsg = err.response?.data?.message;
          setError(errMsg);
          navigate(`/unable-to-logout`);
        }
      }
    }
  };

  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["logout"]) {
      trans.fetchTranslations("logout");
    }
  }, [localeCtx?.selectedLocale]);
  useEffect(() => {
    if (!clientId) {
      // redirect to unauthorized
      navigate(`/unauthorized`, { replace: true });
    } else {
      logoutUser();
    }
  }, []);

  return (
    <div className="TemplateMessageOuter">
      <div className="TemplateMessageInner">
        <div className="TemplateMessageBox">
          <div className="errorContainer float-left w-100 text-center">
            {!error && <Loader type={"infinite-spinner"} className="m-b-10" />}
            <div className="errorTxt">
              <div
                className={`errorTxt-h fs-22 font-weight-semi p-b-20 ${
                  error && "tx-red"
                }`}
              >
                {error ??
                  `${
                    trans.fetchLabelKeyTranslation(
                          "LogoutMsg",
                          "Please wait while we log you out of your accounts"
                        )
                  }`}
              </div>
              <div className="errorTxt-p fs-16 p-b-20">
                {error ? (
                  <p className="text-center text-muted m-t-6 m-b-0 fs-16">
                    {trans.fetchLabelKeyTranslation("BackToText", "Back To")}{" "}
                    <Link
                      to={`/login?clientId=${clientId}${
                        redirectUri !== null
                          ? `&redirectUri=` + redirectUri
                          : ""
                      }`}
                      className="forgotPasswordLbl btn-link"
                    >
                      {trans.fetchLabelKeyTranslation("LoginText", "Login")}
                    </Link>
                  </p>
                ) : (
                  `${
                    trans.fetchLabelKeyTranslation(
                          "RedirectionText",
                          "You will be automatically redirected to the login page..."
                        )
                  }`
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logout;
