import { Button } from "@progress/kendo-react-buttons";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingOverlay from "../components/LoadingOverlay";
import defaultLogo from "../assets/images/logo.png";
import CustomInput from "../components/CustomInput";
import {
  validateEmail,
  validateOnPasswordPolicy,
  validatePassword,
} from "../utils/validator";
import { Error } from "@progress/kendo-react-labels";
import { useEffect, useState } from "react";
import { ResetPasswordRequest } from "../types/ResetPasswordRequest";
import accountService from "../services/account.service";
import { AxiosError } from "axios";
import { Loader } from "@progress/kendo-react-indicators";
import Swal from "sweetalert2";
import useLocale from "../hooks/useLocale";
import { Dictionary } from "../types/Dictionary";
import useBranding from "../hooks/useBranding";
import { ValidateResetToken } from "../types/ValidateResetToken";
import {
  PasswordConditionsSatisfied,
  PasswordPolicy,
} from "../types/PasswordPolicy";
import useTranslation from "../hooks/useTranslation";

const ResetPassword: React.FC = () => {
  const trans = useTranslation("resetpassword")
  const localeCtx = useLocale();
  const brandingCtx = useBranding();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [redirecting, setRedirecting] = useState<boolean>(false);

  const [showPasswordVerbiage, setpasswordVerbiage] =
    useState<PasswordConditionsSatisfied>({
      isNumRequired: false,
      isSpecialCharRequired: false,
      isBothCaseCharRequired: false,
      passwordLength: false,
      isWhitespaceRestricted: true,
    });
  const [passwordPolicy, setPasswordPolicy] = useState<PasswordPolicy>();
  const [isPasswordPolicySatisfied, setIsPasswordPolicySatisfied] =
    useState<boolean>(false);

  const user = searchParams.get("user");
  const token = searchParams.get("token");
  const clientId = searchParams.get("clientId");
  const localeSelected = searchParams.get("language");

  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["resetpassword"]) {
      trans.fetchTranslations("resetpassword");
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    validateToken();
    if (!user || !token || !clientId) {
      // redirect to unauthorized
      navigate(`/unauthorized?language=${localeSelected}`, { replace: true });
    } else {
      getPasswordPolicy();
      // if(localeSelected)
      // {
      //   var locale = localeCtx?.supportedLocales.find(x => {
      //     return x.code === localeSelected
      //   })
      //   if(locale !== undefined)
      //   {
      //     localeCtx?.setUserLocale(locale)
      //   }
      // }
    }
  }, []);

  const validateToken = async () => {
    let resettokenVal: ValidateResetToken = {
      Email: user,
      ResetToken: token,
    };

    let isValid: boolean = await accountService.validateresettoken(
      resettokenVal,
      clientId ?? ""
    );

    if (!isValid)
      navigate(`/not-found?language=${localeSelected}`, { replace: true });
  };

  const getPasswordPolicy = async () => {
    try {
      const result: PasswordPolicy = await accountService.passwordPolicy(user);
      setPasswordPolicy(result);
    } catch (ex) {
      navigate(`/unauthorized?language=${localeSelected}`, { replace: true });
    }
  };
  
  const emailValidator = (value: string) => {
    if (!validateEmail(value)) {
      return `${
        trans.fetchLabelKeyTranslation(
              "InvalidEmailText",
              "Invalid email address."
            )
      }`;
    }

    return "";
  };

  const setShowPasswordVerbiage = (e: { target?: any; value?: any }) => {
    if (passwordPolicy !== undefined) {
      let response: PasswordConditionsSatisfied = validateOnPasswordPolicy(
        e.value,
        passwordPolicy,
        true
      );
      setpasswordVerbiage(response);

      const isPswrdPolicySatisfied = !Object.values(response).includes(false);
      setIsPasswordPolicySatisfied(isPswrdPolicySatisfied);
    }
  };

  const passwordValidator = (
    showPasswordVerbiage: PasswordConditionsSatisfied
  ) => {
    return (
      <Error>
        <div>
          {`${
            trans.fetchLabelKeyTranslation(
                  "PasswordContainText",
                  "Password must contain:"
                )
          }`}
          <ul>
            {passwordPolicy?.passwordLength !== undefined &&
              passwordPolicy.passwordLength > 0 &&
              showPasswordVerbiage.passwordLength === false && (
                <li>
                  {`${
                    trans.fetchLabelKeyTranslation(
                          "MinCharLen",
                          "Minimum character length:"
                        )
                  }`}{" "}
                  &nbsp;
                  {passwordPolicy.passwordLength}
                </li>
              )}

            {passwordPolicy?.isSpecialCharRequired !== undefined &&
              passwordPolicy.isSpecialCharRequired === true &&
              showPasswordVerbiage.isSpecialCharRequired === false && (
                <li>
                  {`${
                    trans.fetchLabelKeyTranslation(
                          "SpecialChar",
                          "Atleast one special character"
                        )
                  }`}
                </li>
              )}

            {passwordPolicy?.isNumRequired !== undefined &&
              passwordPolicy.isNumRequired === true &&
              showPasswordVerbiage.isNumRequired === false && (
                <li>
                  {`${
                    trans.fetchLabelKeyTranslation("Number", "Atleast one number")
                  }`}
                </li>
              )}

            {passwordPolicy?.isBothCaseCharRequired !== undefined &&
              passwordPolicy.isBothCaseCharRequired === true &&
              showPasswordVerbiage.isBothCaseCharRequired === false && (
                <li>{`${
                  trans.fetchLabelKeyTranslation(
                        "UpLowCaseText",
                        "At least one uppercase letter and one lowercase letter."
                      )
                }`}</li>
              )}

            {showPasswordVerbiage.isWhitespaceRestricted === false && (
              <li>{`${
                trans.fetchLabelKeyTranslation("WhitespaceText", "No whitespaces")
              }`}</li>
            )}
          </ul>
        </div>
      </Error>
    );
  };

  const submitHandler = async (values: { [name: string]: any }) => {
    if (
      isPasswordPolicySatisfied &&
      values.password === values.confirmPassword
    ) {
      try {
        setLoading(true);
        if (token && clientId) {
          const reqPayload: ResetPasswordRequest = {
            email: values.email,
            newPassword: values.password,
            forgotPasswordToken: token,
          };
          await accountService.resetPassword(reqPayload, clientId);
          await Swal.fire({
            icon: "success",
            title: `${
              trans.fetchLabelKeyTranslation(
                    "SuccessTitle",
                    "Password Reset Successfully"
                  )
            }`,
            html: `${
              trans.fetchLabelKeyTranslation(
                    "SuccessText",
                    "Your new password has been set <br> Click OK to go to the login page"
                  )
            }`,
          });
          navigate(`/login?clientId=${clientId}`, { replace: true });
        }
      } catch (err) {
        if (err instanceof AxiosError) {
          const errCode = err.response?.data?.code;
          if (errCode === "OLD_PASSWORD_VALIDATION") {
            setError(
              trans.fetchLabelKeyTranslation(
                    "NewPassNotSameOldPass",
                    "New Password can't be same as old password"
                  )
            );
          } else {
            const errMsg = err.response?.data?.message;
            setError(errMsg);
          }
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="loginFull float-left w-100 h-100">
      <div className="loginBg h-100 p-l-15 p-r-15">
        <div className="row m-b-20 h-100">
          <div className="col-md-12 h-100">
            {redirecting && (
              <LoadingOverlay
                customStyle={{ position: "fixed", marginTop: "55px" }}
                themeColor={"light"}
                size={"medium"}
                loadingText={`${
                  trans.fetchLabelKeyTranslation(
                        "LoadingText",
                        "Redirecting to login page�"
                      )
                }`}
              />
            )}
            <div className="formGroup h-100">
              <div
                className="formInrG cardEffect"
                style={{ position: "relative" }}
              >
                {brandingCtx?.branding?.logos.find(
                  (l) => l.name === "HeaderPrimaryLogo"
                )?.logoImageUrl && (
                  <div className="hdrTrk-logo text-center p-t-5 p-b-15">
                    {/* <span className="fs-22 font-weight">LOGO_PLACEHOLDER</span> */}
                    <img
                      src={
                        brandingCtx?.branding?.logos.find(
                          (l) => l.name === "HeaderPrimaryLogo"
                        )?.logoImageUrl
                      }
                      alt="Logo"
                    />
                  </div>
                )}
                <Form
                  initialValues={{
                    email: user,
                    password: "",
                    confirmPassword: "",
                  }}
                  onSubmit={submitHandler}
                  render={(formRenderProps) => (
                    <FormElement style={{ maxWidth: "100%" }}>
                      <fieldset className={"k-form-fieldset"}>
                        <legend
                          className={"k-form-legend fs-18 font-weight-semi"}
                          style={{ textTransform: "none" }}
                        >
                          {trans.fetchLabelKeyTranslation(
                                "ResetPasswordText",
                                "Reset Password"
                              )}
                        </legend>
                        {error && <Error>{error}</Error>}
                        <div className="m-b-15">
                          <Field
                            disabled
                            placeholder={`${
                              trans.fetchLabelKeyTranslation(
                                    "EmailFieldPlaceholder",
                                    "Email"
                                  )
                            }`}
                            name="email"
                            type="email"
                            value={formRenderProps.valueGetter("email")}
                            component={CustomInput}
                            validator={emailValidator}
                          />
                        </div>
                        <div className="m-b-15">
                          <Field
                            placeholder={`${
                              trans.fetchLabelKeyTranslation(
                                    "PasswordFieldPlaceholder",
                                    "Password"
                                  )
                            }`}
                            name="password"
                            type="password"
                            value={formRenderProps.valueGetter("password")}
                            component={CustomInput}
                            onChange={setShowPasswordVerbiage}
                          />
                          {!isPasswordPolicySatisfied &&
                            passwordValidator(showPasswordVerbiage)}
                          {/* {formRenderProps.modified &&
                            passwordValidator(
                              formRenderProps.valueGetter("password")
                            )} */}
                        </div>
                        <div className="m-b-1">
                          <Field
                            placeholder={`${
                              trans.fetchLabelKeyTranslation(
                                    "ConfirmPasswordPC",
                                    "Confirm Password"
                                  )
                            }`}
                            name="confirmPassword"
                            type="password"
                            value={formRenderProps.valueGetter(
                              "confirmPassword"
                            )}
                            component={CustomInput}
                          />
                          {formRenderProps.valueGetter("confirmPassword")
                            .length > 0 &&
                            formRenderProps.valueGetter("password") !==
                              formRenderProps.valueGetter(
                                "confirmPassword"
                              ) && (
                              <Error>
                                {trans.fetchLabelKeyTranslation(
                                      "ErrorTextPasswordNotMatched",
                                      "Password and confirm password must match"
                                    )}
                              </Error>
                            )}
                        </div>
                      </fieldset>
                      <div className="k-form-buttons d-flex justify-content-center m-t-15">
                        <Button
                          type={"submit"}
                          style={{ width: "100%", textTransform: "uppercase" }}
                          className={`btn bg-primary text-white fs-16 p-t-7 p-b-7`}
                          disabled={
                            formRenderProps.valueGetter("confirmPassword")
                              .length === 0 ||
                            formRenderProps.valueGetter("password").length ===
                              0 ||
                            formRenderProps.valueGetter("email").length === 0
                          }
                        >
                          {loading ? (
                            <Loader
                              size="small"
                              type="infinite-spinner"
                              themeColor="light"
                            />
                          ) : (
                            `${
                              trans.fetchLabelKeyTranslation(
                                    "ResetButton",
                                    "Reset"
                                  )
                            }`
                          )}
                        </Button>
                      </div>
                    </FormElement>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
