import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import msIcon from "../assets/images/ms-icon.svg";
import defaultLogo from "../assets/images/logo.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "./Login.scss";
import CustomInput from "../components/CustomInput";
import { validateEmail, validatePassword } from "../utils/validator";
import { Error } from "@progress/kendo-react-labels";
import accountService from "../services/account.service";
import { UserLoginRequest } from "../types/UserLoginRequest";
import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { Loader } from "@progress/kendo-react-indicators";
import { useMsal } from "@azure/msal-react";
import { AccountInfo, SilentRequest } from "@azure/msal-browser";
import LoadingOverlay from "../components/LoadingOverlay";
import { AppTokenRequest } from "../types/AppTokenRequest";
import { Dictionary } from "../types/Dictionary";
import useLocale from "../hooks/useLocale";
import Swal from "sweetalert2";
import useBranding from "../hooks/useBranding";
import { UserTempTokenRequest } from "../types/UserTempTokenRequest";
import useTranslation from "../hooks/useTranslation";

const Login: React.FC = () => {
  const trans = useTranslation("login")
  const brandingCtx = useBranding();
  const localeCtx = useLocale();
  const { instance: microsoftInstance } = useMsal();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState<string>();
  const [userLoggingIn, setUserLoggingIn] = useState<boolean>(false);
  const [redirecting, setRedirecting] = useState<boolean>(false);
  const [accountDisabled, setAccountDisabled] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [autoFill, setAutoFill] = useState<boolean>(false);

  const clientId = searchParams.get("clientId");
  const redirectUri = searchParams.get("redirectUri");
  const isAutoMsLoginEnabled = searchParams.get("isAutoMsLoginEnabled");

  //#region useEffects
  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["login"]) {
      trans.fetchTranslations("login");
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    if (!clientId) {
      // redirect to unauthorized
      navigate(`/unauthorized`, { replace: true });
    } else {
      getRegisteredAppToken(clientId);
    }
  }, []);

  useEffect(() => {
    if (isAutoMsLoginEnabled) {
      userThirdPartyLoginHandler("Microsoft");
    }
  }, []);
  //#endregion useEffects

  //#region Handlers
  const getRegisteredAppToken = async (clientId: string) => {
    try {
      setRedirecting(true);
      const reqPayload: AppTokenRequest = {
        redirectUri: redirectUri ?? "",
      };
      const appTokenResponse = await accountService.getAppToken(
        reqPayload,
        clientId
      );
      const redirectUrl = new URL(appTokenResponse.redirectUri);
      if (redirectUrl.search === "") {
        appTokenResponse.redirectUri = `${appTokenResponse.redirectUri}?appToken=${appTokenResponse.token}`;
      } else {
        appTokenResponse.redirectUri = `${appTokenResponse.redirectUri}&appToken=${appTokenResponse.token}`;
      }

      appTokenResponse.redirectUri = `${
        appTokenResponse.redirectUri
      }&username=${encodeURIComponent(
        appTokenResponse.username
      )}&language=${encodeURIComponent(
        localeCtx?.selectedLocale?.current.locale.code ?? "en-GB"
      )}`;

      let additionalSearchParam = "";
      searchParams.forEach((value, key) => {
        if (key !== "clientId" && key !== "redirectUri") {
          additionalSearchParam = additionalSearchParam + `&${key}=${value}`;
        }
      });
      if (additionalSearchParam !== "") {
        appTokenResponse.redirectUri =
          appTokenResponse.redirectUri + additionalSearchParam;
      }
      window.location.replace(appTokenResponse.redirectUri);
    } catch (err) {
      setShowForm(true);
      if (err instanceof AxiosError) {
        const errCode = err.response?.data?.code;
        if (errCode !== "UNAUTHORIZED") {
          const errMessage = err.response?.data?.message;
          setError(errMessage);
        }
      }
    } finally {
      setRedirecting(false);
    }
  };

  const getAPIAccessTempToken = async (reqPayload: UserTempTokenRequest) => {
    try {
      if (clientId) {
        const token = await accountService.tempToken(reqPayload, clientId);
        setAccountDisabled(false);

        // redirect it to the mfa page
        let mfaUrl = `/mfa?clientId=${clientId}&username=${reqPayload.username}
        &tempToken=${token}&redirectUri=${redirectUri}`;
        let additionalSearchParam = "";
        searchParams.forEach((value, key) => {
          if (key !== "clientId" && key !== "redirectUri") {
            additionalSearchParam = additionalSearchParam + `&${key}=${value}`;
          }
        });
        if (additionalSearchParam !== "") {
          mfaUrl = mfaUrl + additionalSearchParam;
        }
        navigate(mfaUrl, { replace: true });
        // getRegisteredAppToken(clientId);
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        const errCode = err.response?.data?.code;
        if (errCode === "ACCOUNT_LOCKED") {
          setAccountDisabled(true);
          setError(
            trans.fetchLabelKeyTranslation(
                  "AccountLocked",
                  "Account Locked for 30 Minutes"
                )
          );
        } else if (errCode === "PASSWORD_EXPIRED") {
          setAccountDisabled(true);
          setError(
            trans.fetchLabelKeyTranslation(
                  "PasswordExpired",
                  "Password Expired. Please reset the password."
                )
          );
        } else if (errCode === "USER_NOT_REGISTERED_ERROR") {
          setAccountDisabled(true);
          setError(
            trans.fetchLabelKeyTranslation(
                  "UserNotRegisteredError",
                  "User is not registered"
                )
          );
        } else if (errCode === "VALIDATION_ERROR") {
          setAccountDisabled(true);
          setError(
            trans.fetchLabelKeyTranslation(
                  "OneOrMoreValidation",
                  "One or more validation errors occurred"
                )
          );
        } else if (errCode === "UNAUTHORIZED") {
          setAccountDisabled(true);
          setError(
            trans.fetchLabelKeyTranslation("InvalidCreds", "Invalid Credentials")
          );
        } else if (errCode === "INTERNAL_SERVER_ERROR") {
          setAccountDisabled(true);
          setError(
            trans.fetchLabelKeyTranslation(
                  "InternalServerErrorText",
                  "Something went wrong!"
                )
          );
        } else {
          const errMessage = err.response?.data?.message;
          setError(errMessage);
        }
      }
    }
  };

  const userLoginHandler = async (values: { [name: string]: any }) => {
    const reqPayload: UserTempTokenRequest = {
      username: values.email,
      password: values.password,
    };
    setUserLoggingIn(true);
    await getAPIAccessTempToken(reqPayload);
    setUserLoggingIn(false);
  };

  const getMicrosoftGraphToken = async (account: AccountInfo) => {
    const tokenRequest: SilentRequest = {
      scopes: ["User.Read", "openid", "profile"],
      account: account,
    };

    let token: string | undefined;
    try {
      const { accessToken } = await microsoftInstance.acquireTokenSilent(
        tokenRequest
      );
      token = accessToken;
    } catch (e) {
      const { accessToken } = await microsoftInstance.acquireTokenPopup(
        tokenRequest
      );
      token = accessToken;
    }

    return token;
  };

  const userThirdPartyLoginHandler = async (
    thirdPartyProvider: "Microsoft"
  ) => {
    try {
      if (clientId) {
        switch (thirdPartyProvider) {
          case "Microsoft": {
            const { account } = await microsoftInstance.loginPopup();
            if (account) {
              const token = await getMicrosoftGraphToken(account);
              const reqPayload: UserTempTokenRequest = {
                username: account.username,
                accessToken: token,
                thirdPartyProvider: "Microsoft",
              };
              await getAPIAccessTempToken(reqPayload);
            }
            break;
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  };
  //#endregion Handlers

  const emailValidator = (value: string) => {
    if (!value) {
      return (
        <Error>
          {trans.fetchLabelKeyTranslation(
                "EmailEmptyErrText",
                "Email address cannot be empty."
              )}
        </Error>
      );
    }
    if (!validateEmail(value)) {
      return (
        <Error>
          {trans.fetchLabelKeyTranslation(
                "InvalidEmailErrText",
                "Invalid email address."
              )}
        </Error>
      );
    }
  };

  const passwordValidator = (value: string) => {
    if (!value) {
      return (
        <Error>
          {" "}
          {trans.fetchLabelKeyTranslation(
                "PasswordEmptyErrText",
                "Password cannot be empty."
              )}
        </Error>
      );
    }
  };

  return (
    <div className="loginFull float-left w-100 h-100">
      <div className="loginBg h-100 p-l-15 p-r-15">
        <div className="row m-b-20 h-100">
          <div className="col-md-12 h-100">
            {redirecting && (
              <LoadingOverlay
                customStyle={{ position: "fixed", marginTop: "55px" }}
                themeColor={"light"}
                size={"medium"}
              />
            )}
            {showForm && (
              <div className="formGroup h-100">
                <div
                  className="formInrG cardEffect"
                  style={{ position: "relative" }}
                >
                  {brandingCtx?.branding?.logos.find(
                    (l) => l.name === "HeaderPrimaryLogo"
                  )?.logoImageUrl && (
                    <div className="hdrTrk-logo text-center p-t-5 p-b-15">
                      {/* <span className="fs-22 font-weight">LOGO_PLACEHOLDER</span> */}
                      <img
                        src={
                          brandingCtx?.branding?.logos.find(
                            (l) => l.name === "HeaderPrimaryLogo"
                          )?.logoImageUrl
                        }
                        alt="Logo"
                      />
                    </div>
                  )}
                  <Form
                    initialValues={{
                      email: "",
                      password: undefined,
                    }}
                    onSubmit={userLoginHandler}
                    render={(formRenderProps) => {
                      let emailInput = document.getElementById("email");
                      let passwordInput = document.getElementById("password");

                      setTimeout(() => {
                        //we are using chrome's pseudo CSS class which is ':-internal-autofill-selected'
                        //why? because if a particular field matches this class then it means that field
                        //is autofilled by google and so we are checking for both fields whether email and
                        // password are autofilled or not. If yes, we would just enable the submit button.
                        // and we are using setTimeout, because this class is not available just after page
                        // reload. So, after around 500ms, we will check for the class.
                        if (
                          emailInput?.matches(":-internal-autofill-selected") &&
                          passwordInput?.matches(":-internal-autofill-selected")
                        ) {
                          setAutoFill(true);
                        }
                      }, 500);

                      return (
                        <FormElement style={{ maxWidth: "100%" }}>
                          <fieldset className={"k-form-fieldset"}>
                            <legend
                              className={"k-form-legend fs-18 font-weight-semi"}
                              style={{ textTransform: "none" }}
                            >
                              {trans.fetchLabelKeyTranslation(
                                    "LoginFormTitle",
                                    "Sign in to your account"
                                  )}
                            </legend>
                            {error && <Error>{error}</Error>}
                            <div className="m-b-15">
                              <Field
                                placeholder={
                                  trans.fetchLabelKeyTranslation(
                                        "EmailFieldPlaceholder",
                                        "Email"
                                      )
                                }
                                id="email"
                                name="email"
                                value={formRenderProps.valueGetter("email")}
                                component={CustomInput}
                                onChange={(event) => {
                                  if (event.value === "") setAutoFill(false);
                                  setError("");
                                  setAccountDisabled(false);
                                }}
                              />
                              {formRenderProps.modified &&
                                emailValidator(
                                  formRenderProps.valueGetter("email")
                                )}
                            </div>
                            <div className="m-b-1">
                              <Field
                                placeholder={
                                  trans.fetchLabelKeyTranslation(
                                        "PasswordFieldPlaceholder",
                                        "Password"
                                      )
                                }
                                name="password"
                                type="password"
                                id="password"
                                value={formRenderProps.valueGetter("password")}
                                component={CustomInput}
                                onChange={(event) => {
                                  if (event.value === "") setAutoFill(false);
                                  setError("");
                                  setAccountDisabled(false);
                                }}
                              />
                              {/* NOTE: This code should be uncommented if we need strict 
                            password validations in the login page */}
                              {formRenderProps.modified &&
                                passwordValidator(
                                  formRenderProps.valueGetter("password")
                                )}
                            </div>
                          </fieldset>
                          <div className="k-form-buttons d-flex justify-content-center m-t-15">
                            <Button
                              type={"submit"}
                              style={{
                                width: "100%",
                                textTransform: "uppercase",
                              }}
                              className={`btn bg-primary text-white fs-16 p-t-7 p-b-7 ${
                                accountDisabled && "disabledBtn"
                              }`}
                              disabled={
                                accountDisabled ||
                                (!formRenderProps.valueGetter("password") &&
                                  !autoFill) ||
                                (!formRenderProps.valueGetter("email") &&
                                  !autoFill)
                              }
                            >
                              <i className="bi bi-lock fs-14 p-r-5"></i>
                              {userLoggingIn ? (
                                <Loader
                                  size="small"
                                  type="infinite-spinner"
                                  themeColor="light"
                                />
                              ) : trans.fetchLabelKeyTranslation(
                                  "LoginButtonText",
                                  "Login"
                                )
                              }
                            </Button>
                          </div>
                          <div className="row m-t-10">
                            <div className="col-md-12">
                              <div className="formLabel p-0 d-flex justify-content-space-between">
                                <Link
                                  to={`/forgot-password?clientId=${clientId}${
                                    redirectUri !== null
                                      ? `&redirectUri=` + redirectUri
                                      : ""
                                  }`}
                                  className="forgotPasswordLbl btn-link"
                                >
                                  {trans.fetchLabelKeyTranslation(
                                        "ForgotPasswordText",
                                        "Forgot Password ?"
                                      )}
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="or-divider p-t-10 p-b-10">
                            <span className="orDividerSpan">
                              <span className="orDividerSpanInner">
                                {trans.fetchLabelKeyTranslation(
                                      "SignInByThirdPartyText",
                                      "Or sign in with"
                                    )}
                              </span>
                            </span>
                          </div>
                          <div className="k-form-buttons d-flex justify-content-center m-t-10">
                            <Button
                              id="msLoginBUtton"
                              type={"button"}
                              style={{
                                width: "100%",
                                textTransform: "uppercase",
                              }}
                              className={`btn bg-primary text-white fs-16 p-t-7 p-b-7 imgBtn`}
                              onClick={() =>
                                userThirdPartyLoginHandler("Microsoft")
                              }
                            >
                              <img
                                className="p-r-5"
                                src={msIcon}
                                height="24spx"
                                width="24px"
                              />
                              {trans.fetchLabelKeyTranslation(
                                    "TextMicrosoft",
                                    "Microsoft"
                                  )}
                            </Button>
                          </div>
                        </FormElement>
                      );
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
