import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingOverlay from "../components/LoadingOverlay";
import defaultLogo from "../assets/images/logo.png";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import CustomInput from "../components/CustomInput";
import {
  validateEmail,
  validatePassword,
  validateOnPasswordPolicy,
} from "../utils/validator";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { ChangePasswordRequest } from "../types/ChangePasswordRequest";
import accountService from "../services/account.service";
import { AxiosError } from "axios";
import useLocale from "../hooks/useLocale";
import { Dictionary } from "../types/Dictionary";
import Swal from "sweetalert2";
import useBranding from "../hooks/useBranding";
import {
  PasswordPolicy,
  PasswordConditionsSatisfied,
} from "../types/PasswordPolicy";
import useTranslation from "../hooks/useTranslation";

const ChangePassword: React.FC = () => {
  const trans = useTranslation("changepassword")
  const localeCtx = useLocale();
  const brandingCtx = useBranding();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [redirecting, setRedirecting] = useState<boolean>(false);
  const [showPasswordVerbiage, setpasswordVerbiage] =
    useState<PasswordConditionsSatisfied>({
      isNumRequired: false,
      isSpecialCharRequired: false,
      isBothCaseCharRequired: false,
      passwordLength: false,
      isWhitespaceRestricted: true,
    });
  const [passwordPolicy, setPasswordPolicy] = useState<PasswordPolicy>();
  const [isPasswordPolicySatisfied, setIsPasswordPolicySatisfied] =
    useState<boolean>(false);

  const clientId = searchParams.get("clientId");
  const redirectUri = searchParams.get("redirectUri");
  const redirectBackTo=searchParams.get("redirectBackTo");
  const decodedRedirectBackTo = atob(redirectBackTo??"")
  const decodedRedirectBackToURI=new URL(decodedRedirectBackTo);

  const urlSearchParams=new URLSearchParams(decodedRedirectBackToURI.search);
  const queryParamsObj=Object.fromEntries(urlSearchParams.entries());
  const keysArray=Object.keys(queryParamsObj).filter((s:string)=>s!=="language");
  let decodedRedirectBackToURIFiltered=decodedRedirectBackToURI.origin + decodedRedirectBackToURI.pathname;
  for(let i=0;i<keysArray.length;i++){
    decodedRedirectBackToURIFiltered+=`${i===0?"?":"&"}${keysArray[i]}=${queryParamsObj[keysArray[i]]}`
  }

  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["changepassword"]) {
      trans.fetchTranslations("changepassword");
    }
  }, [localeCtx?.selectedLocale]);

  const getPasswordPolicy = async () => {
    try {
      const result: PasswordPolicy = await accountService.passwordPolicy(null);
      setPasswordPolicy(result);
    } catch (ex) {
      navigate(`/unauthorized`, { replace: true });
    }
  };

  useEffect(() => {
    if (!clientId) {
      // redirect to unauthorized
      navigate(`/unauthorized`, { replace: true });
    } else {
      getPasswordPolicy();
      //   var locale = localeCtx?.supportedLocales.find(x => {
      //     return x.code === localeSelected
      //   })
      //   if(locale !== undefined)
      //   {
      //     localeCtx?.setUserLocale(locale)
      //   }
    }
  }, []);

  const submitHandler = async (values: { [name: string]: any }) => {
    if (
      isPasswordPolicySatisfied &&
      values.password === values.confirmPassword
    ) {
      try {
        setLoading(true);
        if (clientId) {
          const reqPayload: ChangePasswordRequest = {
            oldPassword: values.oldPassword,
            newPassword: values.password,
          };
          await accountService.changePassword(reqPayload, clientId);
          await Swal.fire({
            icon: "success",
            title: `${
              trans.fetchLabelKeyTranslation(
                "SuccessTitle",
                "Password Changed Successfully"
              )
              }`,
            text: `${
              trans. fetchLabelKeyTranslation(
                "SuccessText",
                "Your password has been successfully reset. Redirecting you back to login page"
              )
              }`,
          });
          setRedirecting(true);
          navigate(
            `/login?clientId=${clientId}${
              redirectUri !== null ? `&redirectUri=` + redirectUri : ""
            }`,
            {
              replace: true,
            }
          );
          setRedirecting(false);
        }
      } catch (err) {
        if (err instanceof AxiosError) {
          const errCode = err.response?.data?.code;
          if (errCode === "SIMILAR_NEW_PASSWORD_ERROR") {
            setError(
              trans.fetchLabelKeyTranslation(
                  "SimilarNewPasswordText",
                  "New Password can't be same as the current Password"
                )
            );
          } else if (errCode === "VALIDATION_ERROR") {
            setError(
              trans.fetchLabelKeyTranslation(
                  "InvalidUserText",
                  "User does not exist"
                )
            );
          }
          else if (errCode === "VALIDATION_ERROR") {
            setError(
              trans.fetchLabelKeyTranslation(
                  "InvalidUserText",
                  "User does not exist"
                )
            );
          } else if (errCode === "UNAUTHORIZED") {
            setError(
              trans.fetchLabelKeyTranslation(
                  "InvalidCreds",
                  "Invalid Credentials"
                )
            );
          } else if (errCode === "INTERNAL_SERVER_ERROR") {
            setError(
              trans.fetchLabelKeyTranslation(
                  "InternalServerErrorText",
                  "Something went wrong!"
                )
            );
          } else if (errCode === "OLD_PASSWORD_VALIDATION") {
            let errorMsg = trans.fetchLabelKeyTranslation("NewPassNotSameOldPass",`New Password can't be same as previous ${passwordPolicy?.passwordHistoryCount} passwords`);
            let formattedErrorMsg = errorMsg.replace('{}', passwordPolicy?.passwordHistoryCount ? passwordPolicy?.passwordHistoryCount.toString() : '');
            setError(formattedErrorMsg);
          } else {
            const errMsg = err.response?.data?.message;
            setError(errMsg);
          }
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const setShowPasswordVerbiage = (e: { target?: any; value?: any }) => {
    if (passwordPolicy !== undefined) {
      let response: PasswordConditionsSatisfied = validateOnPasswordPolicy(
        e.value,
        passwordPolicy,
        true
      );
      setpasswordVerbiage(response);

      const isPswrdPolicySatisfied = !Object.values(response).includes(false);
      setIsPasswordPolicySatisfied(isPswrdPolicySatisfied);
    }
  };

  const passwordValidator = (
    showPasswordVerbiage: PasswordConditionsSatisfied
  ) => {
    return (
      <Error>
        <div>
          {`${
            trans.fetchLabelKeyTranslation(
              "PasswordContainText",
              "Password must contain:"
            )
            }`}
          <ul>
            {passwordPolicy?.passwordLength !== undefined &&
              passwordPolicy.passwordLength > 0 &&
              showPasswordVerbiage.passwordLength === false && (
                <li>
                  {`${
                    trans.fetchLabelKeyTranslation(
                      "MinCharLen",
                      "Minimum character length:"
                    )
                    }`} &nbsp;
                  {passwordPolicy.passwordLength}
                </li>
              )}

            {passwordPolicy?.isSpecialCharRequired !== undefined &&
              passwordPolicy.isSpecialCharRequired === true &&
              showPasswordVerbiage.isSpecialCharRequired === false && (
                <li>
                  {`${
                    trans.fetchLabelKeyTranslation(
                      "SpecialChar",
                      "Atleast one special character"
                    )
                    }`}
                </li>
              )}

            {passwordPolicy?.isNumRequired !== undefined &&
              passwordPolicy.isNumRequired === true &&
              showPasswordVerbiage.isNumRequired === false && (
                <li>
                  {`${
                    trans.fetchLabelKeyTranslation("Number", "Atleast one number")
                    }`}
                </li>
              )}

            {passwordPolicy?.isBothCaseCharRequired !== undefined &&
              passwordPolicy.isBothCaseCharRequired === true &&
              showPasswordVerbiage.isBothCaseCharRequired === false && (
                <li>{`${
                  trans.fetchLabelKeyTranslation(
                    "UpLowCaseText",
                    "At least one uppercase letter and one lowercase letter."
                  )
                  }`}</li>
              )}

            {showPasswordVerbiage.isWhitespaceRestricted === false && (
              <li>{`${
                trans.fetchLabelKeyTranslation("WhitespaceText", "No whitespaces")
                }`}</li>
            )}
          </ul>
        </div>
      </Error>
    );
  };

  const backHandler = () => {
    window.location.replace(`${decodedRedirectBackToURIFiltered}${keysArray.length===0?"?":"&"}language=${localeCtx?.selectedLocale?.current.locale.code}`)
  };

  return (
    <div className="loginFull float-left w-100 h-100">
      <div className="loginBg h-100 p-l-15 p-r-15">
        <div className="row m-b-20 h-100">
          <div className="col-md-12 h-100">
            {redirecting && (
              <LoadingOverlay
                customStyle={{ position: "fixed", marginTop: "55px" }}
                themeColor={"light"}
                size={"medium"}
                loadingText={`${
                  trans.fetchLabelKeyTranslation(
                    "LoadingText",
                    "Redirecting to login page�"
                  )
                  }`}
              />
            )}
            <div className="formGroup h-100">
              <div
                className="formInrG cardEffect"
                style={{ position: "relative" }}
              >
                {brandingCtx?.branding?.logos.find(
                  (l) => l.name === "HeaderPrimaryLogo"
                )?.logoImageUrl && (
                    <div className="hdrTrk-logo text-center p-t-5 p-b-15">
                      {/* <span className="fs-22 font-weight">LOGO_PLACEHOLDER</span> */}
                      <img
                        src={
                          brandingCtx?.branding?.logos.find(
                            (l) => l.name === "HeaderPrimaryLogo"
                          )?.logoImageUrl
                        }
                        alt="Logo"
                      />
                    </div>
                  )}
                <Form
                  initialValues={{
                    oldPassword: "",
                    password: "",
                    confirmPassword: "",
                  }}
                  onSubmit={submitHandler}
                  render={(formRenderProps) => (
                    <FormElement style={{ maxWidth: "100%" }}>
                      <fieldset className={"k-form-fieldset"}>
                        <legend
                          className={"k-form-legend fs-18 font-weight-semi"}
                          style={{ textTransform: "none" }}
                        >
                          {trans.fetchLabelKeyTranslation(
                              "ChangePasswordText",
                              "Change Password"
                            )}
                        </legend>
                        {error && <Error>{error}</Error>}
                        <div className="m-b-15">
                          <Field
                            placeholder={`${
                              trans.fetchLabelKeyTranslation(
                                "OldPasswordPC",
                                "Old Password"
                              )
                              }`}
                            name="oldPassword"
                            type="password"
                            value={formRenderProps.valueGetter("oldPassword")}
                            component={CustomInput}
                          />
                          {formRenderProps.valueGetter("oldPassword").length ==
                            0 && (
                              <Error>
                                {trans.fetchLabelKeyTranslation(
                                    "ErrorTextOldPassword",
                                    "Old Password can't be empty"
                                  )}
                              </Error>
                            )}
                        </div>
                        <div className="m-b-15">
                          <Field
                            placeholder={`${
                              trans.fetchLabelKeyTranslation(
                                "PasswordPC",
                                "Password"
                              )
                              }`}
                            name="password"
                            type="password"
                            value={formRenderProps.valueGetter("password")}
                            component={CustomInput}
                            onChange={setShowPasswordVerbiage}
                          />
                          {!isPasswordPolicySatisfied &&
                            passwordValidator(showPasswordVerbiage)}
                        </div>
                        <div className="m-b-1">
                          <Field
                            placeholder={`${
                              trans.fetchLabelKeyTranslation(
                                "ConfirmPasswordPC",
                                "Confirm Password"
                              )
                              }`}
                            name="confirmPassword"
                            type="password"
                            value={formRenderProps.valueGetter(
                              "confirmPassword"
                            )}
                            component={CustomInput}
                          />
                          {formRenderProps.valueGetter("confirmPassword")
                            .length > 0 &&
                            formRenderProps.valueGetter("password") !==
                            formRenderProps.valueGetter(
                              "confirmPassword"
                            ) && (
                              <Error>
                                {trans.fetchLabelKeyTranslation(
                                    "ErrorTextPasswordNotMatched",
                                    "Password and Confirm Password must match"
                                  )}
                              </Error>
                            )}
                        </div>
                      </fieldset>

                      <div className="k-form-buttons d-flex justify-content-center m-t-15">
                        <Button
                          type={"submit"}
                          style={{ width: "100%", textTransform: "uppercase" }}
                          className={`btn bg-primary text-white fs-16 p-t-7 p-b-7 
                          ${(formRenderProps.valueGetter("oldPassword").length === 0 ||
                              formRenderProps.valueGetter("password").length === 0 ||
                              formRenderProps.valueGetter("confirmPassword").length === 0 ||
                              formRenderProps.valueGetter("password") !== formRenderProps.valueGetter("confirmPassword") ||
                              isPasswordPolicySatisfied == false) && "disabledBtn"}
                          `}
                          disabled={
                            formRenderProps.valueGetter("oldPassword").length === 0 ||
                            formRenderProps.valueGetter("password").length === 0 ||
                            formRenderProps.valueGetter("confirmPassword").length === 0 ||
                            formRenderProps.valueGetter("password") !== formRenderProps.valueGetter("confirmPassword") ||
                            isPasswordPolicySatisfied == false
                          }
                        >
                          {loading ? (
                            <Loader
                              size="small"
                              type="infinite-spinner"
                              themeColor="light"
                            />
                          ) : (
                            `${
                              trans.fetchLabelKeyTranslation(
                                "SubmitButton",
                                "Submit"
                              )
                            }`
                          )}
                        </Button>
                      </div>
                    </FormElement>
                  )}
                />
                <p className="text-center text-muted m-t-6 m-b-0 fs-16">
                  {trans.fetchLabelKeyTranslation("GoText", "Go")}{" "}
                  <span
                    onClick={backHandler}
                    className="forgotPasswordLbl cursor-pointer btn-link"
                  >
                    {trans.fetchLabelKeyTranslation("BackText", "Back")}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
