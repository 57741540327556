import { useState } from "react";
import useLocale from "./useLocale";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { Dictionary } from "../types/Dictionary";

function useTranslation(componentName: string) {
    const localeCtx = useLocale();
    const [translationsLoading, setTranslationsLoading] = useState<boolean>(false);
    const [translations, setTranslations] = useState<Dictionary<string> | undefined>(
        localeCtx?.selectedLocale?.current.componentTranslations[componentName]
    );

    const fetchLabelKeyTranslation = (
        key: string,
        defaultValue: string
    ): string => {
        return translations && translations[key] ? translations[key] : defaultValue;
    };

    const fetchTranslations = async (component: string) => {
        try {
            setTranslationsLoading(true);
            const resp = await localeCtx?.setComponentTranslations(component);
            setTranslations(resp);
        } catch (err) {
            console.error(err);
            setTranslations(
                localeCtx?.selectedLocale?.previous.componentTranslations[component]
            );
            localeCtx?.setPreviousAppLocale(component);
            if (localeCtx?.localeSwitchFailed) {
                const swalOptions: SweetAlertOptions<any, any> = {
                    icon: "error",
                    title: "Error",
                    text: "Couldn't Switch Language",
                };
                Swal.fire(swalOptions);
            }
        } finally {
            setTimeout(() => {
                setTranslationsLoading(false);
            }, 100);
        }
    };

    return { fetchLabelKeyTranslation, translations, translationsLoading, fetchTranslations, setTranslations };
}

export default useTranslation;