import { useEffect, useState } from "react";
import useLocale from "../hooks/useLocale";
import { Dictionary } from "../types/Dictionary";
import Swal from "sweetalert2";
import useTranslation from "../hooks/useTranslation";

const ServiceUnreachable: React.FC = () => {
  const trans = useTranslation("serviceunreachable")
  const localeCtx = useLocale();

  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["serviceunreachable"]) {
      trans.fetchTranslations("serviceunreachable");
    }
  }, [localeCtx?.selectedLocale]);

  return (
    <div
      style={{ height: "100vh" }}
      className="listMobile d-flex align-items-center justify-content-center bg-white-14"
    >
      <div>
        <div
          className="p-b-10"
          style={{ fontSize: "60px", textAlign: "center", opacity: ".7" }}
        >
          <i className="bi bi-exclamation-triangle text-primary"></i>
        </div>
        <p
          style={{ textAlign: "center" }}
          className="fs-22 font-weight-semi p-10"
        >
          {trans.fetchLabelKeyTranslation(
                "ServiceUnavailableText",
                "Service Unavailable"
              )}
        </p>
        <ul>
          <li className="fs-16 p-b-10">
            {trans.fetchLabelKeyTranslation(
                  "ServiceUnavailableReasonTextOne",
                  "The server encountered an error and could not complete your request. Please try again later."
                )}
          </li>
          <li className="fs-16">
            {trans.fetchLabelKeyTranslation(
                  "ServiceUnavailableReasonTextTwo",
                  "Try checking your connection."
                )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ServiceUnreachable;
